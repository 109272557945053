@import '../../styles/colors.scss';

.custom-input {
    position: relative;
    display: flex;

    &-input {
        padding: 18px 16px 5px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 98%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        align-self: center;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }
    }

    .input-error {
        border: 1px solid $error;
    }

    label {
        position: absolute;
        left: 16px;
        color: $grey-300;
        font-size: 14px;
        font-weight: 600;
        pointer-events: none;
        transform: translate(0,13px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label {
        transform: translate(0, 5px) scale(0.75);
    }

    .filled {
        transform: translate(0, 5px) scale(0.75);
        font-size: 13px;
    }
}

.error-message-input {
    color: $error;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    margin-top: 4px;
    gap: 4px;
}