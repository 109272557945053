@import '../../styles/colors.scss';

.actividad {
    display: flex;
    flex-direction: column;
    height: 82%;
    max-height: 82%;
    padding-top: 10px;
    overflow: auto;

    &-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        margin-top: 32px;
    }

    &-accordion {
        margin-top: 38px;
        margin-bottom: 24px;

        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &-container {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        &-icon {
            width: 16px;
            height: 16px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &-category {
            font-size: 12px;
            font-weight: 800;
            color: $grey-500;

            &-item {
                padding: 11px 8px;
                background-color: $grey-50;
                border-radius: 8px;
                font-weight: 600;
                font-size: 14px;

                &-selected {
                    background-color: $grey-100;
                }
            }
        }

        .MuiAccordion-root {
            border: 0 !important;
            box-shadow: none;

            &:before {
                background-color: transparent !important;
            }
        }

        .MuiAccordionSummary-root {
            padding: 0 !important;
            min-height: 42px !important;
        }

        .MuiAccordionDetails-root {
            padding: 0 !important;
        }

        .Mui-expanded {
            margin: 0 !important;
        }
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: auto;
        padding-bottom: 16px;
    }

    &-lista-seleccionadas {
        margin-top: 16px;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            padding: 4px 8px;
            border-radius: 8px;
            background-color: $grey-50;
            font-size: 12px;
            font-weight: 800;
            color: $grey-500;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 4px;

            svg {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}