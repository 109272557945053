@import '../../styles/colors.scss';

.dropdown-input {
    position: relative;

    .chevron-down {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        transition: all 200ms ease-in-out;
    }

    &-container {
        display: flex;
        justify-content: center;
    }

    &-input {
        padding: 18px 16px 5px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 98%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }

        &:focus + .chevron-down {
            transform: rotate(180deg);
        }
    }

    .input-error {
        border: 1px solid $error;
    }
    
    label {
        position: absolute;
        left: 16px;
        color: $grey-300;
        font-size: 16px;
        font-weight: 600;
        pointer-events: none;
        transform: translate(0,13px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label {
        transform: translate(0, 5px) scale(0.75);
    }

    .filled {
        transform: translate(0, 5px) scale(0.75);
    }
}

.MuiAutocomplete-paper {
    background-color: #ffffff !important;
    padding: 8px 8px 8px 0px !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    border: 1px solid #EBEAF0;
}

.MuiAutocomplete-option {
    background-color: #ffffff !important;
    font-size: 14px !important;
    color: $grey-500 !important;
    font-family: 'Nunito Sans' !important;
    height: 40px !important;
}

.Mui-focused {
    color: $blue-500 !important;
    font-weight: 800;
}

.error-message-input {
    color: $error;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    margin-top: 4px;
    gap: 4px;
}