.layout-confirmar {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 80%;
    overflow: auto;

    &-content {
        padding-bottom: 80px;
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        &-titles {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    &-actions {
        position: absolute;
        bottom: 16px;
        width: 92%;
    }

    &-steps {
        display: flex;
        gap: 10px;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;

            path {
                fill: #171717;
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}