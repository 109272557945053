.espera {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
}