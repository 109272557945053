@import '../../styles/colors.scss';

.actividad {
    display: flex;
    flex-direction: column;
    height: 82%;
    max-height: 82%;
    padding-top: 10px;
    overflow: auto;

    &-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        margin-top: 32px;
    }

    &-accordion {
        margin-top: 38px;
        margin-bottom: 24px;

        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &-category {
            font-size: 12px;
            font-weight: 800;
            color: $grey-500;

            &-item {
                padding: 11px 8px;
                background-color: $grey-50;
                border-radius: 8px;
                font-weight: 600;
                font-size: 14px;

                &-selected {
                    background-color: rgb(229, 241, 251);
                }
            }
        }

        .MuiAccordion-root {
            border: 0 !important;
            box-shadow: none;

            &:before {
                background-color: transparent !important;
            }
        }

        .MuiAccordionSummary-root {
            padding: 0 !important;
            min-height: 42px !important;
        }

        .MuiAccordionDetails-root {
            padding: 0 !important;
        }

        .Mui-expanded {
            margin: 0 !important;
        }
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: auto;
        padding-bottom: 16px;
    }
}