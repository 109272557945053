@import '../../styles/colors.scss';

.login {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #2B363B;

    &-svg-custom {
        svg {
            width: 72px;
            height: 72px;
        }
    }

    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        padding-top: 20px;
        height: 10%;

        &-main {      
            display: flex;
            justify-content: center;
            width: 100%;
            
            span {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                color: #635F76;
                text-align: center;
                width: 100%;
            }
    
            &-arrow {
                margin-right: auto;
            }
        }
    }

    &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: 85%;

        &-tip {
            margin-top: auto;
            margin-bottom: 23px;
        }

        &-steps {
            margin-bottom: 54px;
        }

        .codigo-correcto {
            display: flex;
            margin-bottom: 24px;
            margin-top: 24px;
            background-color: #E7FCF2;
            padding: 8px;
            gap: 8px;
            width: 165px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;

            span {
                font-size: 14px;
                font-weight: 800;
                color: #53BD8A;
            }

            &-svg {
                display: flex;
                justify-content: center;
                align-items: center;

                path {
                    stroke: #53BD8A;
                }
            }
        }

        h1 {
            margin-bottom: 16px;
            font-size: 26px;
            font-weight: 600;
        }

        &> p {
            margin-bottom: 33px;
            font-weight: 400;
            font-size: 14px;
        }
    }

    &-svg {
        width: 72px;
        height: 72px;

        img {
            width: 72px;
            height: 72px;
        }
    }

    &-actions {
        position: absolute;
        bottom: 16px;
        width: 92%;
    }

    &-info {
        margin-top: 20px;

        &-icon {
            width: 21px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &-item {
                display: flex;
                gap: 4px;
                align-items: center;

                img {
                    width: 24px;
                    height: 24px;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

.lista-bloques {
    max-height: 70%;
}

.checkbox-tos {
    margin-bottom: 24px;
}