@import '../../styles/colors.scss';

.ev-completada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    gap: 32px;
    font-size: 14px;

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue-50;
        border-radius: 99999px;
        width: 100px;
        height: 100px;
        
        svg {
            width: 75px;
            height: 75px;
            
            path {
                fill: $blue-500;
            }
        }
    }    
}