@import '../../styles/colors.scss';

.attach-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    input[type="file"] {
        display: none;
    }

    &-button {
        display: flex;
        width: 100%;
        height: 31px;
        border-radius: 8px;
        background-color: $blue-50;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        &-addicon {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                
                path {
                    stroke: $blue-500;
                    stroke-width: 3;
                }
            }
        }

        svg {
            path {
                fill: $blue-500;
            }
        }

        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 90px;
                height: 90px;
                opacity: 0.1;
            }
        }
    }

    img {
        width: 96px;
        height: 96px;
        border-radius: 8px;
        object-fit: cover;
    }

    &-thumbnail {
        position: relative;
        height: 96px;

        div {
            position: absolute;
            bottom: 4px;
            right: 4px;
            background-color: $grey-900;
            width: 28px;
            height: 28px;
            border-radius: 32px;
            border: 2px solid $grey-400;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-file {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-default {
            display: flex;
            flex-direction: column;
            gap: 16px;

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 800;
                color: $grey-500;
            }
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 800;
            color: $grey-500;
        }

        &-actions {
            display: flex;
            gap: 4px;

            &-edit {
                cursor: pointer;
                
                svg {
                    path {
                        stroke: $blue-500;
                    }
                }
            }

            &-delete {
                cursor: pointer;
                
                svg {
                    path {
                        stroke: $error;
                    }
                }
            }
        }
    }
 }

 .color-without-fill {
    svg {
        path {
            stroke: $blue-500;
            fill: none;
        }
    }
 }