@import '../../styles/colors.scss';

.toggle-buttons {
    min-height: 64px;
    padding: 16px;
    box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    width: 98%;

    &-option {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &-label {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;

            &-text {
                display: flex;
                flex-direction: column;
            }

            span {
                color: $grey-500;
                font-size: 12px;
                font-weight: 400;
                white-space: pre-wrap;
            }
        }
    }

    &-expanded {
        margin-top: 24px;
    }

    &-checkbox {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        label {
            width: 20px;
            height: 20px;
        }

        input[type="checkbox"] {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
            cursor: pointer;
        }

        &-icon {
            display: inline-block;
            height: 20px;
            width: 20px;
            background-color: $grey-100;
            border: 0;
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
        
            &-active {
                background-color: $blue-500;
                border: 0;
            }
        }
    }
}

.always-expanded-card {
    padding-left: 36px;
}