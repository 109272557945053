@import '../../styles/colors.scss';

.search-input {
    position: relative;
    display: flex;
    justify-content: center;

    input {
        padding: 12px 16px 12px 48px;
        width: 99%;
        height: 48px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        color: $grey-900;
        font-weight: 600;
        font-size: 16px;

        &::placeholder {
            color: $grey-300;
            font-weight: 400;
            font-size: 16px;
        }

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }
    }

    svg {
        position: absolute;
        top: 14px;
        left: 18px;
    }
}