@import '../../styles/colors.scss';

.bienes-list {
    &-button {
        border-radius: 8px;
        background-color: $blue-50;
        border: none;
        width: 100%;
        padding: 8px 16px 8px 24px;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        margin-top: 24px;
        height: 48px;
        cursor: pointer;

        &:disabled {
            background-color: $grey-50;
            
            span {
                color: $grey-300;
            }

            svg {
                path {
                    stroke: $grey-300;
                }
            }
        }
        
        span {
            color: $blue-500;
            font-size: 14px;
            font-weight: 800;
        }
    }

    &-cards {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;
        align-items: center;

        & > span {
            color: $grey-500;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: -8px;
            align-self: flex-start;
        }
    }
}