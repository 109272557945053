@import '../../styles/colors.scss';

.multiple-choice {
    position: relative;
    
    &:not(:last-of-type) {
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: $grey-900;
        margin-bottom: 8px;
    }

    .p-margin {
        margin-top: 32px;
    }

    ul {
        list-style: none;

        li:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }

    ul:not(:last-of-type) {
        margin-bottom: 16px;
    }

    .separator {
        &::after {
            position: absolute;
            content: '';
            height: 1px;
            background-color: $grey-100;
            width: 100%;
        }
    }

    &:not(:last-of-type)::after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        background-color: $grey-100;
        bottom: 0;
        left: 0;
    }
}

.text-area {
    padding: 14px 16px;
    width: 100%;
    min-height: 104px;
    margin-top: 12px;
    border: 1px solid $grey-200;
    border-radius: 8px;
    resize: none;
    font-size: 16px;
    font-weight: 600;

    &:focus {
        box-shadow: 0 0 0 1px $blue-500;
        outline: none;
        border-color: transparent;
    }

    &:disabled {
        background-color: $grey-50;
        border-color: $grey-50;
        color: $grey-300;
    }
}