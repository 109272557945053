@import '../../styles/colors.scss';

.header {
    width: 100%;
    height: 10%;
    overflow: hidden;

    &-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 8px;

        span {
            flex: 1;
            text-align: center;
            margin-right: 40px;
            font-size: 14px;
            font-weight: 600;
            color: $grey-500;
        }
    }

    &-progressbar {
        display: flex;
        justify-content: center;
        margin-top: 7px;
    }

    &-arrow {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: auto;
    }
}