@import '../../styles/colors.scss';

.primary {
    border: 0;
    border-radius: 12px;
    padding: 11px 24px;
    background-color: $blue-500;
    color: #ffffff;
    font-weight: 800;
    font-size: 14px;
    height: 48px;
    width: 100%;
    cursor: pointer;

    &:active {
        background-color: $blue-800;
    }

    &:disabled {
        background-color: $grey-200;
    }
}

.secondary {
    border: 0;
    border-radius: 12px;
    background-color: transparent;
    color: $grey-500;
    font-weight: 800;
    font-size: 14px;
    height: 48px;
    width: 100%;
    cursor: pointer;

    &:active {
        background-color: $grey-200;
    }

    &:disabled {
        color: $grey-300;
    }
}

.button-loader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.mb8 {
    margin-bottom: 8px;
}