.data-error {
    padding-top: 56px;
    height: 82vh;

    &-logo {
        margin-bottom: 56px;

        img {
            height: 40px;
        }
    }

    &-svg {
        width: 72px;
        height: 72px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        &-title {
            font-size: 26px;
            font-weight: 600;
        }

        &-msg {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 40px;
        }
    }

    &-tip {
        margin-top: auto;
    }
}