.layout-pregunta {
    height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
}

.layout-pregunta-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 80px;

    &-actions {
        position: absolute;
        bottom: 16px;
        width: 92%;
    }
}

.layout-pregunta-content-title {
    display: flex;
    gap: 4px;
    align-items: flex-end;
}

.layout-pregunta-content-ayuda {
    height: 24px;
    width: 24px;
}

.loader-message {
    font-size: 12px;
    color: #ffffff;
}

.safari-content {
    padding-bottom: 50px;
}

.total-mobiliario {
    box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.50);
    padding: 20px 16px;
    width: 98%;
    align-self: center;
    border-radius: 8px;
}