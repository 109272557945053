@import '../../styles/colors.scss';

.info-card {
    background-color: $grey-50;
    padding: 16px;

    p {
        font-weight: 600;
        font-size: 12px;
        color: $grey-500;
        margin-bottom: 8px;
    }
    
    ul {
        list-style: none;
    }

    &-item {
        color: $grey-500;
        font-weight: 400;
        font-size: 12px;
        position: relative;
        padding: 8px 0;

        &:not(:last-of-type)::after {
            content: "";
            height: 1px;
            width: 100%;
            position: absolute;
            background-color: $grey-100;
            bottom: 0;
            left: 0;
        }
    }
}