@import '../../styles/colors.scss';

.layout-mensaje {
    width: 100%;
    height: 95vh;
    padding: 32px 24px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    text-align: center;
    gap: 24px;

    h1 {
        font-size: 16px;
        font-weight: 800;
    }

    &-top {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    &-data {
        background-color: $grey-50;
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        text-align: center;

        p {
            font-size: 14px;
            font-weight: 400;
        }

        span {
            font-weight: 800;
            font-size: 16px;
        }
    }

    &-recordatorio {
        color: $blue-500;
        font-size: 14px;
        font-weight: 800;
    }
}