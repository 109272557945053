.carga-invitacion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    text-align: center;
    height: 100%;

    p {
        max-width: 464px;
        font-size: 22px;
    }
}