@import '../../styles/colors.scss';

.stepper {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 100%;

    .step {
        width: 100%;
        margin: 5px;
        border-radius: 36px;

        .MuiLinearProgress-root {
            background-color: $grey-100 !important;
        }
    
        .MuiLinearProgress-bar {
            background-color: $blue-100 !important;
        }

        .linearProgressActive {
            background-color: $blue-500 !important;
            width: 100%;
            border-radius: 36px;
        }
    }
}

