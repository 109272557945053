@import '../../styles/colors.scss';

.bienes {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.5);
    border-radius: 8px;
    position: relative;
    width: 98%;

    &-remove-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;

         svg {
            path {
                stroke: $grey-400;
            }
         }
    }

    &-close {
        display: flex;
        justify-content: center;
        transform: rotate(180deg);

        path {
            stroke: $blue-500;
        }
    }

    &-open {
        display: flex;
        justify-content: center;

        path {
            stroke: $blue-500;
        }
    }

    &-photo {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    &-data {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-detalle {
            font-size: 16px;
            font-weight: 600;
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-importe {
            font-size: 16px;
            font-weight: 800;
        }
    }

    &-tag {
        background-color: $blue-50;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        border-radius: 7px;
        font-size: 12px;
        color: $blue-500;
        font-weight: 800;
    }
}

.añadir-msg {
    background-color: transparent;
    padding: 15px;
    font-size: 14px;
    font-weight: 800;
    color: $blue-500;
    border: 0;
    cursor: pointer;

    &:disabled {
        color: $grey-300;
    }
}

.dropdown-input {
    position: relative;

    .chevron-down {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        transition: all 200ms ease-in-out;
    }

    &-container {
        display: flex;
        justify-content: center;
    }

    &-input {
        padding: 18px 16px 5px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 98%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }

        &:focus + .chevron-down {
            transform: rotate(180deg);
        }
    }

    .input-error {
        border: 1px solid $error;
    }
    
    label {
        position: absolute;
        left: 16px;
        color: $grey-300;
        font-size: 16px;
        font-weight: 600;
        pointer-events: none;
        transform: translate(0,13px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label {
        transform: translate(0, 5px) scale(0.75);
    }

    .filled {
        transform: translate(0, 5px) scale(0.75);
    }
    
    .MuiOutlinedInput-root {
        background-color: #ffffff !important;
        padding: 8px 8px 8px 0px !important;
        border-radius: 12px !important;
        box-shadow: none !important;
        border: 1px solid #EBEAF0;
    }
    
    .MuiPaper-root {
        background-color: #ffffff !important;
        padding: 8px 8px 8px 0px !important;
        border-radius: 12px !important;
        box-shadow: none !important;
        border: 1px solid #EBEAF0;
    }

    .error-message-input {
        color: $error;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        margin-top: 4px;
        gap: 4px;
    }
}
