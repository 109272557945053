.choice-card {
    width: 100%;
    display: flex;
    justify-content: center;

    &-container {
        width: 98%;
        padding: 13px 16px;
        box-shadow: 0px 2px 8px 2px rgba(215, 221, 229, 0.5);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-header {
        display: flex;
        gap: 8px;
        align-items: center;

        &-title {
            font-weight: 600;
        }
        
        &-icon {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-options {
        padding-left: 36px;
    }
}