@import '../../styles/colors.scss';

.block-title {
    display: flex;
    flex-direction: column;

    .block-title-icon {
        width: 20px;
        height: 20px;

        svg {
            width: 20px;
            height: 20px;
            color: $grey-900;

            path {
                fill: $grey-900;
            }
        }
    }

    .block-title-icon2 {
        width: 20px;
        height: 20px;

        svg {
            width: 20px;
            height: 20px;
            color: $grey-900;

            path {
                stroke: $grey-900;
            }
        }
    }

    h1 {
        color: $grey-900;
        font-size: 20px;
        font-weight: 600;
    }
}

.content-ayuda {
    height: 24px;
    width: 24px;
    vertical-align: middle;
}