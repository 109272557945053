.layout-carga {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    height: 80%;
    align-items: center;
    text-align: center;
    padding: 24px;

    &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    &-svg {
        width: 55px;
        height: 55px;
        position: relative;
        margin-bottom: 16px;

        &::after {
            content: "";
            position: absolute;
            top: -15px;
            left: -15px;
            width: 87px;
            height: 87px;
            border-radius: 50%;
            background-color: #EBF1FE;
            z-index: -1;
        }
    }

    &-msg1 {
        font-size: 18px;
        font-weight: 800;
    }

    &-msg2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 32px;
    }
}