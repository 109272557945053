@import '../../styles/colors.scss';

.MuiModal-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 16px;
}

.ayuda {
    width: 100%;
    padding: 32px 24px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    gap: 16px;
    height: 100%;

    img {
        width: 90%;
    }

    h1 {
        font-size: 14px;
        font-weight: 800;
    }

    .ayuda-texto {
        color: $grey-500;
        font-size: 14px;
        overflow: auto;

        div {
            color: $grey-500;
        }
    }
}