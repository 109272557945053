@import '../../styles/colors.scss';

.modal-tos {
    background-color: #ffffff;
    max-width: 800px;
    padding: 48px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 90vh;
    overflow-y: auto;

    p {
        margin: 0;
    }

    &-header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &-close {
            svg {
                width: 25px;
                height: 25px;

                path {
                    stroke: black;
                }
            }
        }
    }

    &-text {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-track {
            border-radius: 100vw;
            border: solid 3px transparent;
            box-shadow: inset 0 0 10px 10px $grey-100;
            margin-block: .5rem;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 100vw;
            border: solid 3px transparent;
            box-shadow: inset 0 0 10px 10px $grey-300;
        }

        &-html {
            li {
                margin-left: 1rem;
            }
        }
    }

    &-actions {
        display: flex;
        flex-direction: column;
        gap: 40px;

        button {
            align-self: flex-end;
        }

        &-check {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
                font-size: 14px;
            }
        }
    }
}

.tos-checkbox {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;

    label {
        width: 20px;
        height: 20px;
    }

    input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .checkbox {
        display: inline-block;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border: 1px solid $grey-300;
        border-radius: 4px;
        padding: 2px;
    
        &-active {
            background-color: $blue-500;
        }
    }

    span {
        color: $blue-500;
        text-decoration: underline;
    }
}
