.success-confirmar {
    &-tag {
        display: flex;
        background-color: #E7FCF2;
        padding: 8px;
        gap: 8px;
        width: 179px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        margin-top: 24px;

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                path {
                    stroke: #53BD8A;
                }
            }
        }

        span {
            color: #53BD8A;
            font-size: 14px;
            font-weight: 800;
        }
    }

    &-logo {
        height: 40px;
        margin-top: 24px;
    }

    &-svg {
        width: 72px;
        height: 72px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 64px;

        h1 {
            font-size: 26px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
            font-weight: 400;
        }
    }
}