@import '../../styles/colors.scss';

.gps-error {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    gap: 16px;
    align-items: center;
    text-align: center;
    font-size: 14px;

    button {
        color: #ffffff;
        font-weight: 800;
        padding: 8px 16px;
        background-color: $blue-500;
        border: 0;
        border-radius: 8px;
        font-size: 14px;
    }
}