@import '../../styles/colors.scss';

.registro {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-info {
        padding: 56px 0 0 0;
        display: flex;
        flex-direction: column;
    
        &-logo {
            margin-bottom: 56px;
            
            img {
                height: 40px;
            }
        }
    
        &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
    
            h1 {
                font-size: 26px;
                font-weight: 600;
            }
    
            p {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 64px;
            }
        }
    

    }

    &-sms-input > div {
        display: flex;
        gap: 16px;

        input {
            border: 1px solid #D7DDE5;
            border-radius: 12px;
            font-family: 'Nunito Sans' !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            
            &:first-child {
                border-top-left-radius: 12px !important;
                border-bottom-left-radius: 12px !important;
            }

            &:last-child {
                border-top-right-radius: 12px !important;
                border-bottom-right-radius: 12px !important;
                border-right: solid 1px #D7DDE5;
            }

            &:focus {
                outline: none;
                border: 1px solid $blue-500;
                caret-color: auto;

                &+input {
                    border-left: solid 1px #D7DDE5;
                }
            }
        }
    }

    &-button {
        padding-bottom: 16px;
     }
}


.version {
    font-size: x-small;
    color: gray;
}