@import '../../styles/colors.scss';

.custom-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;

    label {
        width: 20px;
        height: 20px;
    }

    input[type="radio"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
    
    .checkbox {
        display: inline-block;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border: 1px solid $grey-300;
        border-radius: 4px;
        padding: 2px;
    
        &-active {
            background-color: $blue-500;
        }

        &-rounded {
            border-radius: 50%;
            border: 1px solid $grey-200;
            padding: 4px;

            &-active {
                border: 0;   
            }
        }
    }

    span {
        font-size: 14px;
        font-weight: 400;
        color: $grey-900;
    }
}
