@import './styles/colors.scss';

@font-face {
  font-family: 'Nunito sans';
  src: local('Nunito sans'), url(./Assets/fonts/NunitoSans-Regular.woff) format(truetype);
}
@font-face {
  font-family: 'Nunito sans';
  src: local('Nunito sans'), url(./Assets/fonts/NunitoSans-SemiBold.woff) format(truetype);
  font-weight: 600;
}
@font-face {
  font-family: 'Nunito sans';
  src: local('Nunito sans'), url(./Assets/fonts/NunitoSans-Bold.woff) format(truetype);
  font-weight: 700;
}
@font-face {
  font-family: 'Nunito sans';
  src: local('Nunito sans'), url(./Assets/fonts/NunitoSans-ExtraBold.woff) format(truetype);
  font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: 'Nunito sans', sans-serif !important;
  color: $grey-900;
}

.App {
  padding: 0 16px 0 16px;
  height: 100%;
}