@import '../../styles/colors.scss';

.notification {
    display: flex;
    gap: 4px;
    background-color: $grey-50;
    border-radius: 8px;
    padding: 8px 4px;

    p {
        font-size: 12px;
        font-weight: 600;
        color: $grey-500;
    }

    .notification-icon {
        width: 16px;
        height: 16px;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}