@import '../../styles/colors.scss';

.terminos {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;

    h1, h2, p {
        margin: 0;
    }

    table {
        max-width: 80%;
        font-size: 14px;
    }

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    td, th {
        padding: 4px;
        vertical-align: baseline;
    }

    ul, li {
        font-size: 14px;
    }

    a {
        color: #0000EE;
    }

    &-titulo {
        color: $blue-500;
        font-size: 20px;
        font-weight: 800;
    }

    &-fecha {
        font-size: 14px;
    }

    &-seccion {
        h2 {
            font-size: 14px;
            color: $blue-500;
            font-weight: 800;
        }

        p {
            font-size: 14px;
            padding-top: 14px;
        }

        &-parrafos {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }
    }
}

.dash-list {
    list-style-type: '- ';
}

.indent-list {
    padding-left: 10%;
}

.remove-indent {
    padding-left: 10px;
}

.add-v-gap {
    display: flex;
    flex-direction: column;
    gap: 14px;
}