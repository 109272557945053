@import '../../styles/colors.scss';

.datos-empresa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-top: 10px;
    overflow-y: auto;

    &-content {
        flex: 1;
        padding-bottom: 80px;
    }

    &-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        margin-top: 14px;
    }

    &-inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;

        &-direccion {
            display: grid;
            grid-template: 1fr 1fr/ 1fr 1fr;
            grid-column-gap: 8px;
            grid-row-gap: 16px;
        }
    }

    &-buttons {
        position: absolute;
        bottom: 16px;
        width: 92%;
    }
}

.dropdown-input {
    position: relative;

    .chevron-down {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        transition: all 200ms ease-in-out;
    }

    &-container {
        display: flex;
        justify-content: center;
        position: relative;
    }

    &-input {
        padding: 18px 16px 5px 16px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        width: 98%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;

        &:focus {
            box-shadow: 0 0 0 1px $blue-500;
            outline: none;
            border-color: transparent;
        }

        &:disabled {
            background-color: $grey-50;
            border-color: $grey-50;
            color: $grey-300;
        }

        &:focus + .chevron-down {
            transform: rotate(180deg);
        }
    }

    .input-error {
        border: 1px solid $error;
    }
    
    label {
        position: absolute;
        left: 16px;
        color: $grey-300;
        font-size: 16px;
        font-weight: 600;
        pointer-events: none;
        transform: translate(0,13px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &:focus-within label {
        transform: translate(0, 5px) scale(0.75);
    }

    .filled {
        transform: translate(0, 5px) scale(0.75);
    }
}

.MuiAutocomplete-paper {
    background-color: #ffffff !important;
    padding: 8px 8px 8px 0px !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    border: 1px solid #EBEAF0;
}

.MuiAutocomplete-option {
    background-color: #ffffff !important;
    font-size: 14px !important;
    color: $grey-500 !important;
    font-family: 'Nunito Sans' !important;
    height: 40px !important;
}

.Mui-focused {
    color: $blue-500 !important;
    font-weight: 800;
}

.error-message-input {
    color: $error;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    margin-top: 4px;
    gap: 4px;
}

.google-map {
    position: relative;

    &-marker {
        position: absolute;
        width: 35px;
        height: 60px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }
}

.empty-map {
    height: 345px;
    width: 97%;
    background-color: $blue-50;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    p {
        margin: 0;
        color: $grey-300;
        max-width: 143px;
        font-size: 14px;
    }
}

.input-mapa {
    margin-bottom: 16px;

    &-button {
        all: unset;
        position: absolute;
        right: 26px;
        cursor: pointer;
        background-color: $blue-50;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;

        svg {
            width: 22px;
            height: 22px;
            
            path {
                stroke: $blue-500;
            }
        }

        &:disabled {
            background-color: $grey-50;

            svg {                
                path {
                    stroke: $grey-300;
                }
            }
        }
    }
}