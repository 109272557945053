@import '../../styles/colors.scss';

.thumbnail {
    transform: rotate(90deg);
}

.add-photo {
    width: fit-content;

    .Toastify {
        svg {
            color: white;
            fill: white;
        }
    }

    &-button {
        display: flex;
        width: 96px;
        height: 96px;
        border-radius: 8px;
        background-color: $blue-50;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        &-addicon {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                
                path {
                    stroke: $blue-500;
                    stroke-width: 3;
                }
            }
        }

        svg {
            path {
                fill: $blue-500;
            }
        }

        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 90px;
                height: 90px;
                opacity: 0.1;
            }
        }
    }

    input[type="file"] {
        display: none;
    }

    img {
        width: 96px;
        height: 96px;
        border-radius: 8px;
        object-fit: cover;
    }

    &-thumbnail {
        position: relative;
        height: 96px;

        div {
            position: absolute;
            bottom: 4px;
            right: 4px;
            background-color: $grey-900;
            width: 28px;
            height: 28px;
            border-radius: 32px;
            border: 2px solid $grey-400;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
 }

 .color-without-fill {
    svg {
        path {
            stroke: $blue-500;
            fill: none;
        }
    }
 }