@import '../../styles/colors.scss';

.image-block {
    &-title-container {
        margin-bottom: 40px;
    }

    h1 {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 800;
        gap: 8px;
    }

    &-description {
        font-size: 14px;
        color: $grey-500;
        font-weight: 400;
        margin-top: 8px;
    }

    &-title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
    }

    &-tip {
        margin-bottom: 32px;
    }

    &-main {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &-item {
            display: flex;
            flex-direction: column;
            
            span{
                font-size: 12px;
                font-weight: 600;
                color: $grey-500;
                margin-top: 8px;
                max-width: 105px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    &-bottom-bar {
        width: 100%;
        background-color: $grey-200;
        height: 1px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
}